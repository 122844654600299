<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Send for modification</v-card-title>
        <v-card-text>
            Write the reasons why the quote is not approved.
            <v-form ref="formReason" v-model="valid" class="mt-2">
                <v-textarea
                    label="Reasons *"
                    v-model="message"
                    hide-details
                    :rules="[rules.required]"
                    required
                ></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="error" @click="close">
                Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                @click="sendMessage"
                :disabled="!valid"
                color="primary"
                :loading="loading"
                rounded
            >
                Send
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'ReasonsForNotApproving',
    props: {
        quote: {
            type: Object,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        message: null,
        rules: {
            required: v => !!v || 'The value is required',
        },
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async sendMessage() {
            try {
                this.loading = true
                await this.$emit('reOpenQuote', this.quote)
                await API.createPost({
                    chatId: this.quote.id,
                    userId: this.userId,
                    message: `The quote was not approved. ${this.message}`,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
